.root {
    max-height: 212px;
    padding: 10px 12px;
    overflow-y: auto;
}

.root::-webkit-scrollbar {
    width: 12px;
}

.root::-webkit-scrollbar-track {
    background: none;
}

.root::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    background-color: rgba(63, 67, 80, 0.4);
    border-radius: 8px;
}

.contentWrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
}

.inputWrapper {
    display: flex;
    flex: 1;
    align-items: center;
}

.input {
    flex: 1;
}

.iconWrapper {
    display: inline-flex;
    align-items: center;
    font-size: 0;
    line-height: 0;
}

.counter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 8px;
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.56);
}
