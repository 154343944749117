.root {
    cursor: pointer;
}

.input {
    width: 0;
    padding: 0;
    border: 0;
    opacity: 0;
}

.placeholder {
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
}

.value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.value.withLabel,
.placeholder.withLabel {
    align-self: flex-end;
}

.spacer {
    flex: 1;
}

.chevronIcon {
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.64);
    font-size: 0;
    line-height: 0;
}

.chevronIcon.inverted {
    transform: rotate(180deg);
}
