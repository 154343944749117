.overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}

.dialog {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    border: 1px solid rgba(var(--time-webkit-color-accent-rgb, 63, 67, 80), 0.16);
    background-color: rgb(var(--time-webkit-color-background-rgb, 255, 255, 255));
    border-radius: 12px;
    box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.12);
}

.dialog-small {
    max-width: 512px;
}

.dialog-medium {
    max-width: 600px;
}

.dialog-large {
    max-width: 832px;
}

@media (max-width: 831px) {
    .dialog-large {
        max-width: 100%;
    }
}

@media (max-width: 599px) {
    .dialog-medium {
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .dialog {
        max-width: 100% !important;
        padding: 14px;
    }
}

.scrollContainer {
    display: flex;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-top: 24px;
    padding-bottom: 24px;
}

.body {
    box-sizing: border-box;
    padding: 0 24px;
}
