@use "sass:color";

.Avatar {
    &,
    &:focus,
    &.a11y--focused {
        border-radius: 50%;
    }

    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none;    /* Firefox all */
    -ms-user-select: none;     /* IE 10+ */
    user-select: none;
    vertical-align: sub;

    &.Avatar-xxs {
        width: 16px;
        min-width: 16px;
        height: 16px;
        border-radius: 3px;
        font-size: 8px;
    }

    &.Avatar-xs {
        width: 20px;
        min-width: 20px;
        height: 20px;
        border-radius: 4px;
        font-size: 9.5px;
    }

    &.Avatar-sm {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 4px;
        font-size: 10px;
    }

    &.Avatar-md {
        width: $avatar-md-size;
        min-width: $avatar-md-size;
        height: $avatar-md-size;
        border-radius: 6px;
        font-size: 12px;
    }

    &.Avatar-lg {
        width: 48px;
        min-width: 48px;
        height: 48px;
        border-radius: 10px;
        font-size: 14px;
    }

    &.Avatar-xl {
        width: 72px;
        min-width: 72px;
        height: 72px;
        border-radius: 16px;
        font-size: 18px;
    }

    &.Avatar-xxl {
        width: 128px;
        min-width: 128px;
        height: 128px;
        border-radius: 24px;
        font-size: 44px;
    }

    &.Avatar-plain {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: white;

        &::before {
            position: absolute;
            display: inline-flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: rgba(var(--center-channel-color-rgb), 0.64);
            content: attr(data-content);
        }
    }
}
