.SidebarHeader {
    display: flex;
    padding: 0;
    cursor: pointer;

    .MenuWrapper {
        height: 100%;
    }

    .MenuWrapper:first-child {
        width: 100%;
        height: 100%;
    }

    .sidebar-header-dropdown__icon {
        svg {
            fill: var(--sidebar-header-text-color);
        }
    }
}
