.root {
    --input-border-color: rgb(var(--time-webkit-color-secondary-rgb), 0.16);

    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px var(--input-border-color) inset;
    cursor: text;
    outline: none;
    transition-duration: 150ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease-out;
}

.root:hover {
    --input-border-color: rgb(var(--time-webkit-color-secondary-rgb), 0.48);
}

.root:active,
.root:focus-within {
    --input-border-color: rgb(var(--time-webkit-color-main-rgb));
}

.root.focusVisible {
    outline: 2px solid rgb(var(--time-webkit-color-main-rgb));
    outline-offset: -2px;
}

.root.large {
    padding: 8px 16px;
    border-radius: 10px;
}

.root.medium {
    padding: 7px 12px;
    border-radius: 8px;
}

.root.disabled {
    --input-border-color: rgb(var(--time-webkit-color-secondary-rgb), 0.16);

    background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.04);
    pointer-events: none;
}

.root.error {
    --input-border-color: rgb(var(--time-webkit-color-error-rgb));
}

.contentWrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

.contentWrapper.medium {
    height: 30px;
}

.contentWrapper.large {
    height: 36px;
}

.contentWrapper.multiline {
    height: auto;
}

.contentWrapper.medium.multiline {
    min-height: 30px;
}

.contentWrapper.large.multiline {
    min-height: 36px;
}

.label {
    position: absolute;
    top: 50%;
    left: 0;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
    cursor: inherit;
    transform: translate(0, -50%);
    transition: all, 180ms;
}

.label.reduced {
    top: 0;
    transform: translate(0, 0);
}

.label.reduced.medium {
    line-height: 12px;
}

.label.reduced.large {
    line-height: 14px;
}

.label.error {
    color: rgb(var(--time-webkit-color-error-rgb));
}
