.error {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 14px;
    border: 1px solid rgba(var(--time-webkit-color-dnd-indicator-rgb), 0.16);
    margin-top: 26px;
    background: rgba(var(--time-webkit-color-dnd-indicator-rgb), 0.08);
    border-radius: 4px;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 7px;
    fill: rgb(var(--time-webkit-color-error-rgb));
}
