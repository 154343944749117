@charset 'UTF-8';

a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;

    &:hover,
    &:focus {
        color: $primary-color--hover;
        outline: 0;
    }
}

.text-danger {
    color: desaturate($red, 20%);

    &:hover,
    &:focus {
        color: desaturate($red, 20%);
    }
}

.app__body .mention-link,
.app__body .mention--highlight {
    display: inline-block;
    padding: 0 2px 2px;
    border-radius: 4px;
    line-height: 1.1;

    .mention-link,
    .mention--highlight {
        padding: 0;
        line-height: 1;
    }

    & * {
        background-color: transparent !important;
        color: inherit !important;
    }
}

.app__body .mention-link {
    background-color: rgba(var(--link-color-rgb), 0.1);
    color: var(--link-color);

    &:hover {
        background-color: rgba(var(--link-color-rgb), 0.2);
        color: var(--link-color);
        text-decoration: none;
    }
}

.app__body .mention--highlight {
    background-color: var(--mention-highlight-bg);
    color: var(--mention-highlight-link);

    * {
        background-color: transparent !important;
        color: inherit !important;
    }

    &.mention-link:hover {
        background-color: rgba(var(--mention-highlight-bg-rgb), 0.75) !important;
        color: rgba(var(--mention-highlight-link-rgb), 0.75) !important;
        text-decoration: none;
    }
}
