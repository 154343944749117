.root {
    display: inline-flex;
    align-items: center;
    padding: 0;
    border: 0 rgb(var(--time-webkit-color-main-rgb));
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
}

.root.default,
.root.flat,
.root.pseudo {
    color: rgb(var(--time-webkit-color-main-rgb));
}

.root.clear {
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
}

.root:hover {
    filter: brightness(0.92);
    text-decoration: none;
}

.root.clear:hover {
    color: rgb(var(--time-webkit-color-secondary-rgb));
}

.root:hover .text::after,
.root.pseudo .text::after {
    visibility: visible;
}

.root.flat:not(.pseudo):hover .text::after {
    visibility: hidden;
}

.icon,
.iconRight {
    font-size: 0;
    line-height: 0;
}

.icon {
    margin-right: 4px;
}

.iconRight {
    margin-left: 4px;
}

.text {
    position: relative;
}

.text::after {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 1px;
    border-width: 0;
    border-style: dashed;
    border-color: inherit;
    border-bottom-width: 1px;
    content: ' ';
    transition: visibility 0.2s cubic-bezier(0.4, 0, 1, 1);
    visibility: hidden;
}
