.react-contextmenu {
    --background: var(--center-channel-bg);
    --color: var(--center-channel-color);
    --color-rgb: var(--center-channel-color-rgb);

    display: none;
    padding: 7px 0;
    border: 1px solid rgba(var(--center-channel-color-rgb), 0.2);
    background: var(--background);
    border-radius: 4px;
    color: var(--color);
    cursor: pointer;
    font-size: 14px;
}

.react-contextmenu--visible {
    z-index: 100;
    display: block;
}

.react-contextmenu-item {
    padding: 6px 16px;
    white-space: nowrap;

    &:hover {
        background: rgba(var(--center-channel-color-rgb), 0.1);
    }

    &:focus,
    span {
        outline: none;
    }

    &.dangerous {
        color: var(--dnd-indicator);

        .icon {
            opacity: 1;
        }

        &:hover {
            background-color: var(--dnd-indicator);
            color: var(--sidebar-text);
        }
    }

    &.selected {
        padding-right: 42px;

        .icon-check {
            margin-right: -32px;
            color: var(--button-bg);
            float: right;
        }
    }

    .icon {
        padding-right: 12px;
        color: inherit;
        font-size: 16px;
        line-height: 16px;
        opacity: 0.7;

        i::before {
            margin: 0;
        }
    }

    .fa {
        padding-top: 3px;
        padding-left: 12px;
        margin-top: -2px;
        color: rgba(var(--center-channel-color-rgb), 0.56);
        float: right;
        font-size: 19px;
    }

    .selected-text {
        margin-top: 1px;
        margin-left: 10px;
        color: rgba(var(--center-channel-color-rgb), 0.56);
        font-size: 12px;
        text-align: right;
        vertical-align: baseline;
    }
}

.react-contextmenu-submenu {
    padding: 0;

    .react-contextmenu {
        max-height: 70vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.react-contextmenu-item--divider {
    height: 1px;
    padding: 0;
    margin: 8px 0;
    background: rgba(var(--center-channel-color-rgb), 0.08);
}

.react-contextmenu-item--divider:first-child,
.react-contextmenu-item--divider:last-child,
.react-contextmenu-item--divider + .react-contextmenu-item--divider {
    display: none;
}
