.markdown__heading {
    width: 100%;
    margin: 8px 0;
}

.markdown__heading:first-child {
    margin: 0 0 8px 0;
}

.markdown__heading:last-child {
    margin: 8px 0 0 0;
}

.markdown__heading:only-child {
    margin: 0;
}

.blockquote {
    position: relative;
    width: 100%;
    padding: 0 8px;
    border: none;
    margin: 8px 0;
    font-size: inherit;
}

.blockquote:first-child {
    margin: 0 0 8px 0;
}

.blockquote:only-child {
    margin: 0;
}

.blockquote:not(:only-child):last-child {
    margin: 8px 0 0 0;
}

.blockquote::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 2px;
    background-color: rgba(var(--time-webkit-color-secondary-rgb), 0.48);
    border-radius: 0;
    content: '';
    text-decoration: inherit;
}

ul.markdown__list {
    width: 100%;
    padding-left: 22px;
    margin-top: 3px;
    margin-bottom: 0.11em;
}

ol.markdown__list {
    width: 100%;
    padding: 0;
    counter-reset: list;
}

ol.markdown__list > li {
    display: flex;
    width: 100%;
    counter-increment: list;
}

ol.markdown__list > li > p {
    padding: 0;
    margin: 0;
}

ol.markdown__list > li::before {
    padding-right: 8px;
    content: counter(list) '.';
    text-align: right;
}

.paragraph {
    width: 100%;
    padding: 0;
    margin: 0;
}

.markdown__link {
    box-sizing: border-box;
    background-color: transparent;
    color: rgb(var(--time-webkit-color-main-rgb));
    cursor: text;
    text-decoration: none;
    word-break: break-word;
}

.markdown__link:hover {
    text-decoration: underline;
}
