.error_text {
    padding: 0;
    margin: 0;
    color: rgb(var(--time-webkit-color-error-rgb));
}

.errorMessage {
    display: flex;
    align-items: center;
    margin-top: 4px;
}
