@import '../../../../sass/utils/module';

.Menu {
    .MenuHelp {
        display: flex;
        width: 100%;
        align-items: center;
        font-size: 14px;

        > div {
            display: block;
            overflow: hidden;
            width: 100%;
            align-items: center;
            padding: 1px 16px;
            clear: both;
            color: inherit;
            font-weight: normal;
            line-height: 1.8em;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .MenuItem {
        display: flex;
        width: 100%;
        align-items: center;
        font-size: 14px;

        &.MenuItem--with-icon {
            .MenuItem__help-text {
                padding-left: 28px;
            }
        }

        .destructive {
            color: var(--error-text);
        }

        .MenuItem__icon {
            width: 20px;
            margin-right: 8px;
            text-align: center;
        }

        .MenuItem__custom-icon {
            margin: 4px 0;
        }

        .MenuItem__custom-icon,
        .MenuItem__compass-icon {
            color: rgba(var(--center-channel-color-rgb), 0.56);
            fill: rgba(var(--center-channel-color-rgb), 0.56);
        }

        .MenuItem__compass-icon {
            margin: 4px -6px;
        }

        .MenuItem__compass-icon-dangerous {
            margin: 4px -6px;
        }

        .MenuItem__opacity {
            opacity: 0.56;
        }

        span.icon {
            display: inline-block;
            width: 28px; // less 12px padding is 16px
            height: 16px; // less 3px top padding is 16px;
            align-self: center;
            padding-right: 12px;
            line-height: 16px;
        }

        span.MenuItem__primary-text {
            display: inline-flex;
            padding: 5px 0;
            line-height: 22px;
            white-space: normal;
        }

        span.MenuItem__text-color {
            color: rgba(var(--center-channel-color-rgb), 0.56);
        }

        span.MenuItem__help-text {
            display: block;
            padding-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
            opacity: 0.7;
            white-space: normal;

            @media (max-width: 768px) {
                padding-left: 28px !important;

                .mobile-main-menu & {
                    padding-left: 36px !important;
                }
            }
        }

        > div,
        > button,
        > a {
            display: block;
            overflow: hidden;
            width: 100%;
            align-items: center;
            padding: 1px 16px;
            clear: both;
            color: inherit;
            cursor: pointer;
            font-weight: normal;
            line-height: 28px;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.MenuItem__with-help {
                display: block;
                height: auto;

                .MenuItem__primary-text {
                    padding: 7px 0 3px;
                }

                .icon {
                    @media (max-width: 768px) {
                        top: 10px;
                    }
                }
            }

            .MenuItem__icon {
                position: relative;
                top: 4px;
                display: inline-flex;
                width: 20px;
                height: 20px;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
            }

            &.MenuItem__dangerous {
                color: var(--dnd-indicator);

                i {
                    color: var(--dnd-indicator) !important;
                }

                &:hover {
                    background-color: var(--dnd-indicator) !important;
                    color: var(--sidebar-text);

                    i {
                        color: var(--sidebar-text) !important;
                    }
                }
            }
        }

        > a {
            text-decoration: none;

            &:focus,
            &:hover {
                color: inherit;
            }
        }
    }
}

.SubMenu {
    position: absolute;
    top: unset;
    left: unset;
    padding-left: 0;
    cursor: auto;
}

.SubMenu__icon-left {
    position: relative;
    top: 0;
    color: rgba(var(--center-channel-color-rgb), 0.56);
    font-size: 19px;
}

.SubMenu__icon-left-empty {
    position: relative;
    top: 0;
    display: none;
    width: 0;
    font-size: 19px;
    visibility: hidden;
}

.SubMenu__icon-right {
    padding-top: 3px;
    color: rgba(var(--center-channel-color-rgb), 0.56);
    float: right;
    font-size: 19px;
}

.SubMenu__icon-right-empty {
    top: 0;
    display: none;
    width: 0;
    padding: 0;
    margin-left: auto;
    font-size: 19px;
}

.MenuItem__divider {
    border-top: $border-gray;
    margin: 8px 0;
}
