@import 'sass/utils/variables';

.tooltip-container {
    opacity: 0;
    transition: opacity $transition-quick ease-in;
    visibility: hidden;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
}
