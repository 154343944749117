.app__body .iframe-modal {
    width: 970px !important;
    height: 90vh;
    margin-top: 10vh;

    .modal-header {
        min-height: 0;
        padding: 0;
        border: none;
        background: transparent;

        button.close {
            top: 20px;
            right: 20px;
            background: rgba(var(--center-channel-color-rgb), 0.08) !important;
            color: rgba(var(--center-channel-color-rgb), 0.72) !important;
        }
    }

    .modal-body {
        overflow: hidden;
        height: 80vh;
        padding: 0;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}

@media screen and (max-width: 640px) {
    .app__body .iframe-modal .modal-body {
        height: 100vh;
        max-height: 100vh;
    }
}
