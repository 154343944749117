.windows {
    -webkit-font-smoothing: none !important;
}

.base {
    font-family: var(--time-webkit-font-family, "Lato", sans-serif);
    font-feature-settings: 'liga' off;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0;
    text-align: left;
    text-rendering: geometricPrecision;
    text-size-adjust: 100%;
}

.base-color {
    color: rgb(var(--time-webkit-color-secondary-rgb, 63, 67, 80));
}

.Body-XSS-True {
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
}

.Body-XS-True {
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
}

.Body-S-True {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.Body-M-True {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
}

.Body-L-True {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
}

.Body-XL-True {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.Body-XXL-True {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.Heading-6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.Heading-5 {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}

.Heading-4 {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
}

.Heading-3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
}

.Heading-2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.Heading-1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
}

.Heading-Hero {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.Body-XXS {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
}

.Body-XS {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
}

.Body-S {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.Body-M {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.Body-L {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.Body-XL {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.Body-XXL {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}
