.root {
    max-height: 360px;
    overflow-y: auto;
}

.emailIcon {
    display: inline-block;
    width: 36px;
    height: 36px;
    padding: 8px;
    background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.06);
    border-radius: 6px;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.64);
    font-size: 0;
    line-height: 0;
}

.userItem {
    padding-top: 10px;
    padding-bottom: 10px;
}

.subheader {
    margin: 0 0 8px;
}

.subheaderWithTopMargin {
    margin-top: 24px;
}

.avatar {
    margin-right: 12px;
}

.alert {
    margin-bottom: 20px;
}
