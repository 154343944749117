.Badge {
    display: inline-flex;
    align-items: center;
    margin: 0 0 0 4px;
}

.Badge__box {
    padding: 0 4px;
    border: none;
    background: rgba(var(--center-channel-text-rgb), 0.08);
    border-radius: 4px;
    font-family: inherit;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;

    &.OutOfChannelBadge {
        margin-right: 5px;
        background: #f1c40f;
    }
}
