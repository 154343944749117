.base {
    position: relative;
    display: flex;
    overflow: hidden;
    width: var(--avatar-size);
    height: var(--avatar-size);
    box-sizing: border-box;
    flex-direction: column;
    padding: 0;
    margin: 0;
    border-radius: var(--avatar-border-radius);
    color: rgb(var(--time-webkit-color-button-text-rgb));
    font-size: var(--avatar-font-size);
    font-weight: bold;
    line-height: var(--avatar-font-size);
    user-select: none;
}

.baseInline {
    display: inline-flex;
}

.avatar {
    display: flex;
    width: var(--avatar-size);
    height: var(--avatar-size);
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.initials {
    display: flex;
    width: var(--avatar-size);
    height: var(--avatar-size);
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--time-webkit-color-main-rgb));
}

.initialsShown {
    transform: translateY(-100%);
}

.img {
    max-width: 100%;
    height: auto;
}

.size-12 {
    --avatar-size: 12px;
    --avatar-font-size: 10px;
    --avatar-border-radius: 1px;
}

.size-16 {
    --avatar-size: 16px;
    --avatar-font-size: 11px;
    --avatar-border-radius: 2px;
}

.size-20 {
    --avatar-size: 20px;
    --avatar-font-size: 12px;
    --avatar-border-radius: 2px;
}

.size-24 {
    --avatar-size: 24px;
    --avatar-font-size: 14px;
    --avatar-border-radius: 4px;
}

.size-28 {
    --avatar-size: 28px;
    --avatar-font-size: 16px;
    --avatar-border-radius: 4px;
}

.size-32 {
    --avatar-size: 32px;
    --avatar-font-size: 18px;
    --avatar-border-radius: 4px;
}

.size-36 {
    --avatar-size: 36px;
    --avatar-font-size: 20px;
    --avatar-border-radius: 6px;
}

.size-40 {
    --avatar-size: 40px;
    --avatar-font-size: 20px;
    --avatar-border-radius: 6px;
}

.size-48 {
    --avatar-size: 48px;
    --avatar-font-size: 22px;
    --avatar-border-radius: 6px;
}

.size-56 {
    --avatar-size: 56px;
    --avatar-font-size: 25px;
    --avatar-border-radius: 8px;
}

.size-64 {
    --avatar-size: 64px;
    --avatar-font-size: 28px;
    --avatar-border-radius: 8px;
}

.size-72 {
    --avatar-size: 72px;
    --avatar-font-size: 32px;
    --avatar-border-radius: 8px;
}

.size-96 {
    --avatar-size: 96px;
    --avatar-font-size: 36px;
    --avatar-border-radius: 10px;
}

.size-120 {
    --avatar-size: 120px;
    --avatar-font-size: 40px;
    --avatar-border-radius: 10px;
}

.size-128 {
    --avatar-size: 128px;
    --avatar-font-size: 40px;
    --avatar-border-radius: 10px;
}
