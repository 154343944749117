.root {
    display: grid;
    box-sizing: border-box;
    padding: 6px 16px;
    cursor: pointer;
    grid-template-areas:
        'A B C'
        'D F G';
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
}

.root.focused {
    background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.08);
}

.label {
    overflow: hidden;
    padding: 2px 0;
    margin-right: 16px;
    color: rgb(var(--time-webkit-color-secondary-rgb));
    grid-area: B;
}

.labelText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.secondaryLabel {
    padding: 2px 0;
    margin-right: 16px;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
    grid-area: F;
}

.label.withIcon,
.secondaryLabel.withIcon {
    margin-left: 8px;
}

.icon,
.iconRight {
    align-self: center;
    font-size: 0;
    line-height: 0;
}

.icon {
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
    grid-area: A;
}

.iconRight {
    grid-area: C;
}

.textRight {
    display: flex;
    align-items: flex-end;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
    line-height: 16px;
}
