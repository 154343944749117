.SidebarResizeHandler {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: var(--sidebar-left-width, 240px);
    display: block;
    width: 4px;
    margin-left: -2px;
    content: '';
    cursor: ew-resize;
    transition: background-color 0.2s 0.1s ease-in;
}

.SidebarResize__resizing {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: ew-resize;
}

.SidebarResizeHandler:hover,
.SidebarResize__resizing .SidebarResizeHandler {
    background-color: var(--sidebar-text-active-border);
}

.multi-teams {
    .SidebarResizeHandler {
        left: var(--sidebar-left-width, 240px);
    }
}

.SidebarResize__right {
    .SidebarResizeHandler {
        top: 40px;
        right: var(--sidebar-right-width, 400px);
        left: auto;
        margin-right: -2px;
        margin-left: 0;
    }
}

.SidebarResize__thread-list {
    .SidebarResizeHandler {
        top: 62px;
        left: var(--thread-list-width, 350px);
    }

    &.SidebarResize__resizing {
        .SidebarResizeHandler {
            top: 102px;
        }
    }
}
