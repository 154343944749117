.imageLoadedContainer {
    position: relative;
    display: inline-block;
    margin: 0;
    cursor: pointer;
}

.smallImageButtonsWrapper {
    position: relative;
    cursor: pointer;
}

.imageLoadedContainer:hover .actionsContainer,
.smallImageButtonsWrapper:hover .actionsContainer {
    display: inline-block;
}

.actionsContainer {
    position: absolute;
    z-index: 3;
    top: 8px;
    right: 8px;
    display: none;
    padding: 4px;
    border: 1px solid rgba(var(--time-webkit-color-secondary-rgb), 0.16);
    background-color: rgb(var(--time-webkit-color-background-rgb));
    border-radius: 4px;
    box-shadow: var(--time-webkit-elevation-1);
}

.actionsContainer:hover .imageCopyLink,
.actionsContainer:hover .imageDownload {
    color: rgba(var(--time-webkit-color-background-rgb), 0.72);
}

.containerSmallImage {
    position: absolute;
    top: -8px;
    left: 24px;
    width: 58px;
}

.containerSmallImageNoCopyButton {
    left: 32px;
    width: 38px;
    box-sizing: border-box;
}

.imageCopyLink.imageCopyLinkRecentlyCopied {
    background: rgba(var(--time-webkit-color-online-indicator-rgb), 0.16);
}

.imageCopyLink.imageCopyLinkRecentlyCopied svg {
    color: rgb(var(--time-webkit-color-online-indicator-rgb));
}

.imageCopyLink.imageCopyLinkRecentlyCopied:hover {
    background: rgba(var(--time-webkit-color-online-indicator-rgb), 0.16);
}

.imageCopyLink,
.imageDownload {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    padding: 2px;
    border: none;
    background: transparent;
    border-radius: 4px;
    color: rgba(var(--time-webkit-color-background-rgb), 0.56);
    cursor: pointer;
    outline: none;
    text-align: center;
}

.imageCopyLink:hover,
.imageDownload:hover {
    background-color: rgba(var(--time-webkit-color-secondary-rgb), 0.08);
    text-decoration: none;
}

.imageCopyLink svg,
.imageDownload svg {
    padding-top: 2px;
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.56);
}

.imageCopyLink:active,
.imageDownload:active {
    background: rgba(var(--time-webkit-color-main-rgb), 0.08);
}

.imageCopyLink:active svg,
.imageDownload:active svg {
    color: rgb(var(--time-webkit-color-secondary-rgb));
}

.loadingPreviewWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.action {
    position: relative;
    display: inline-block;
}

.smallImageContainer {
    display: flex;
    min-width: 48px;
    max-width: calc(100% - 5px);
    min-height: 48px;
    max-height: inherit;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(var(--time-webkit-color-secondary-rgb), 0.32);
    margin: 5px 2px;
    background: var(--time-webkit-color-secondary-rgb);
    border-radius: 4px;
}

.smallImageContainer:hover {
    z-index: 2;
    box-shadow: 0 2px 5px 0 rgba(var(--time-webkit-color-black-rgb), 0.1), 0 2px 5px 0 rgba(var(--time-webkit-color-black-rgb), 0.1);
}

.hideImage {
    display: none;
}
