.base {
    position: relative;
    display: flex;
    overflow: visible;
    width: auto;
    height: auto;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    margin: 0;
    appearance: button;
    background: none;
    border-radius: var(--btn-border-radius, 0);
    color: var(--btn-text-color);
    cursor: pointer;
    outline: none;
    text-align: center;
    text-transform: none;

    --color-disabled-bg-rgb: var(--time-webkit-color-secondary-rgb);
    --color-disabled-text-rgb: var(--time-webkit-color-button-text-rgb);
    --btn-border-width: 1px;
}

.base::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--btn-background-color);
    border-radius: var(--btn-border-radius);
    box-shadow: 0 0 0 var(--btn-border-width) var(--btn-border-color) inset;
    content: "";
}

.textBase {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textBase.textBase {
    color: currentColor;
}

.base.loading .textBase {
    visibility: hidden;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.large {
    --btn-border-radius: 10px;

    padding: 16px 20px;
}

.medium {
    --btn-border-radius: 8px;

    padding: 12px 16px;
}

.small {
    --btn-border-radius: 6px;

    padding: 10px 12px;
}

.xSmall {
    --btn-border-radius: 5px;

    padding: 6px 10px;
}

.primary {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb));
    --btn-text-color: rgb(var(--time-webkit-color-button-text-rgb));
}

.primary.inverted {
    --btn-background-color: rgb(var(--time-webkit-color-button-text-rgb));
    --btn-text-color: rgb(var(--time-webkit-color-main-rgb));
}

.primary.hover::before {
    filter: brightness(0.96);
}

.primary.active::before {
    filter: brightness(0.9);
}

.secondary {
    --btn-background-color: transparent;
    --btn-text-color: rgb(var(--time-webkit-color-main-rgb));
    --btn-border-color: rgb(var(--time-webkit-color-main-rgb));
}

.secondary.inverted {
    --btn-background-color: rgb(var(--time-webkit-color-button-text-rgb));
    --btn-text-color: rgb(var(--time-webkit-color-button-text-rgb));
}

.secondary.hover {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb), 0.08);
}

.secondary.active {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb), 0.16);
}

.tertiary {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb), 0.08);
    --btn-text-color: rgb(var(--time-webkit-color-main-rgb));
    --btn-border-width: 0;
}

.tertiary.hover {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb), 0.12);
}

.tertiary.active {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb), 0.16);
}

.tertiary.inverted {
    --btn-background-color: rgb(var(--time-webkit-color-button-text-rgb), 0.12);
    --btn-text-color: rgb(var(--time-webkit-color-button-text-rgb));
}

.tertiary.inverted.hover {
    --btn-background-color: rgb(var(--time-webkit-color-button-text-rgb), 0.16);
}

.tertiary.inverted.active {
    --btn-background-color: rgb(var(--time-webkit-color-button-text-rgb), 0.24);
}

.outline {
    --btn-background-color: transparent;
    --btn-border-color: rgb(var(--time-webkit-color-secondary-rgb), 0.16);
    --btn-text-color: rgb(var(--time-webkit-color-main-rgb));
}

.outline.hover {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb), 0.08);
}

.outline.active {
    --btn-background-color: rgb(var(--time-webkit-color-main-rgb), 0.12);
}

.outline.focused {
    --btn-border-width: 0;
}

.outline.disabled {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
}

.flat {
    --btn-background-color: transparent;
    --btn-text-color: rgb(var(--time-webkit-color-main-rgb));
    --btn-border-width: 0;
}

.flat.hover {
    --btn-background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.06);
}

.flat.active {
    --btn-background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.08);
}

.flat.inverted {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
}

.flat.disabled {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
}

.flat.inverted.disabled {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.48);
}

.disabled {
    cursor: not-allowed;
}

.primary.disabled {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
    --btn-background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.06);
}

.primary.inverted.disabled {
    --btn-tet-color: rgb(var(--color-disabled-bg-rgb), 1);
    --btn-background-color: rgb(var(--color-disabled-text-rgb), 0.06);
}

.secondary.disabled {
    --btn-border-color: rgba(var(--color-disabled-bg-rgb), 0.16);
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
}

.secondary.inverted.disabled {
    --btn-border-color: rgba(var(--color-disabled-bg-rgb), 0.16);
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
}

.tertiary.disabled {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
    --btn-border-color: rgb(var(--time-webkit-color-secondary-rgb), 0.06);
    --btn-background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.06);
}

.tertiary.inverted.disabled {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
    --btn-background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.06);
}

.link {
    --btn-background-color: transparent;
    --btn-text-color: rgb(var(--time-webkit-color-main-rgb));

    padding: 0;
}

.link.hover {
    text-decoration-line: underline;
}

.link.disabled {
    --btn-text-color: rgb(var(--time-webkit-color-secondary-rgb), 0.32);

    cursor: not-allowed;
    text-decoration: none;
}

.focused.base {
    outline: 2px solid rgb(var(--time-webkit-color-main-rgb), 0.5);
}

.focused.base.destructive {
    outline: 2px solid rgb(var(--time-webkit-color-error-rgb), 0.5);
}

.primary.destructive,
.primary.destructive.inverted {
    --btn-background-color: rgb(var(--time-webkit-color-error-rgb));
    --btn-text-color: rgb(var(--time-webkit-color-button-destructive-text-rgb));
}

.secondary.destructive,
.secondary.destructive.inverted {
    --btn-text-color: rgb(var(--time-webkit-color-error-rgb));
    --btn-border-color: rgb(var(--time-webkit-color-error-rgb));
}

.tertiary.destructive,
.tertiary.destructive.inverted {
    --btn-background-color: rgb(var(--time-webkit-color-error-rgb), 0.08);
    --btn-text-color: rgb(var(--time-webkit-color-error-rgb));
}
