@keyframes animate-rotate {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(360deg); }
    100% { transform: rotate(1080deg); }
}

.base {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.circle {
    stroke: rgb(var(--time-webkit-color-main-rgb, 22, 109, 224));
    stroke-dasharray: 300;
    stroke-dashoffset: 100%;
    stroke-miterlimit: 0;
    stroke-width: 10px;
}

.svg {
    overflow: hidden;
    animation: animate-rotate 3s linear infinite;
}
