.wrapper {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.wrapper:hover .actions {
    opacity: 1;
}

.avatar {
    min-width: 0;
    flex-shrink: 0;
    margin-right: 10px;
}

.meta {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textSecondary {
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.56);
}

.actions {
    display: flex;
    align-items: center;
    margin-right: 18px;
    gap: 8px;
    opacity: 0;
}
