.footer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 20px 24px;
}

.footer:empty {
    display: none;
}

.confirmFooter {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}

.confirmFooter .button:not(:last-of-type) {
    margin-right: 8px;
}

@media (max-width: 575px) {
    .confirmFooter {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .confirmFooter .button:not(:first-of-type) {
        margin-bottom: 8px;
    }
}
