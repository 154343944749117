@import 'utils/variables';

.Menu {
    .MenuHeader {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 32px;
        align-items: center;
        padding: 0 32px 0 20px;
        clear: both;
        color: inherit;
        cursor: default;
        font-weight: $font-weight--semibold;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.disabled {
            opacity: 0.6;
        }
    }
}
