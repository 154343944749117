.time-list-item {
    display: flex;
    align-items: center;

    > * + * {
        margin-left: 10px;
    }
}

.time-list-item__image {
    display: flex;
    width: 36px;
    min-width: 36px;
    height: 36px;
    align-self: start;
    justify-content: center;
    background: rgba(var(--center-channel-color-rgb), 0.04);
    border-radius: 6px;
    color: rgba(var(--center-channel-color-rgb), 0.72);
    fill: rgba(var(--center-channel-color-rgb), 0.72);
}

.time-list-item__content {
    display: flex;
    overflow: hidden;
    flex: 1;
    flex-direction: column;
}

.time-list-item__label {
    overflow: hidden;
    margin-top: -0.1em;
    color: rgba(var(--center-channel-color-rgb), 0.72);
    font-size: 12px;
    text-overflow: ellipsis;
}

.time-list-item_compact {
    > * + * {
        margin-left: 8px;
    }

    .time-list-item__image {
        width: 16px;
        min-width: 16px;
        height: 16px;
        background: none;
    }

    .time-list-item__content {
        overflow: visible;
        line-height: 1;
    }
}
