.root {
    position: absolute;
    display: inline-block;

    --tooltip-bg-color: rgb(var(--time-webkit-color-tooltip-bg-rgb, 0 0 0));
    --tooltip-elevation: var(--time-webkit-elevation-3);
    --tooltip-border-radius: var(--time-webkit-tooltip-border-radius, 8px);
    --tooltip-padding: 4px 8px;
    --tooltip-max-width: 256px;
}

.anchor {
    position: absolute;
    width: 0;
    height: 0;
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: transparent;
    border-top-color: var(--tooltip-bg-color);
}

.anchor[data-placement=top] {
    top: 100%;
    transform: translate(-50%);
}

.anchor[data-placement=bottom] {
    bottom: 100%;
    transform: translate(-50%) rotate(180deg);
}

.anchor[data-placement=left] {
    left: 100%;
    transform: translate(0, 100%) rotate(-90deg);
    transform-origin: top left;
}

.anchor[data-placement=right] {
    right: 100%;
    transform: translate(0, 100%) rotate(90deg);
    transform-origin: top right;
}

.content {
    max-width: var(--tooltip-max-width);
    padding: var(--tooltip-padding);
    background-color: var(--tooltip-bg-color);
    border-radius: var(--tooltip-border-radius);
    box-shadow: var(--tooltip-elevation);
}
