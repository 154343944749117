.header {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 24px;
}

.header.hasCloser {
    padding-right: 50px;
}

.heading {
    padding: 0;
    margin: 0;
}

.header .heading {
    line-height: 24px;
}

.description {
    padding: 0;
    margin: 0;
    margin-top: 8px;
    color: rgba(var(--time-webkit-color-secondary-rgb, 63, 67, 80), 0.64);
}

@media (max-width: 575px) {
    .header {
        flex-wrap: wrap;

        /* Room for cross */
        padding-right: 44px;
    }

    .confirmFooter .button:not(:first-of-type) {
        margin-bottom: 8px;
    }
}
