@charset 'UTF-8';

@import 'utils/variables';

@media screen and (max-width: 768px) {
    #submenuModal {
        padding: 0;

        .mobile-sub-menu {
            max-width: 100% !important;
            height: 100%;
            margin-top: 0 !important;

            .modal-content {
                height: 100%;
                padding: 0;
                border: unset;
                background: transparent !important;
                box-shadow: unset;

                .modal-body {
                    overflow: unset;
                    height: 100%;
                    max-height: unset;
                    padding: 0;

                    .MenuWrapper {
                        height: 100%;

                        div {
                            height: 100%;
                        }

                        .Menu {
                            padding: 0 1.5em;

                            &.dropdown-menu {
                                position: relative;
                                top: 0;
                                display: flex;
                                width: 100%;
                                max-width: inherit;
                                height: 100%;
                                max-height: inherit;
                                flex-direction: column;
                                justify-content: center;
                                padding-top: 0;
                                border: 0;
                                margin-bottom: 0;
                                background: transparent;
                                box-shadow: none;
                                color: inherit;
                                list-style: none;

                                div,
                                button,
                                a,
                                a:hover {
                                    opacity: 0.8;
                                }

                                .MenuItem {
                                    background: var(--center-channel-bg);

                                    > div,
                                    > button,
                                    > a {
                                        width: 100%;
                                        height: auto;
                                        padding: 15px 20px;
                                        border-top: 1px solid rgba(var(--center-channel-color-rgb), 0.08);
                                        font-size: 14px;
                                        opacity: 1;
                                        text-align: left;
                                    }

                                    &:first-child {
                                        div,
                                        button {
                                            border: none;
                                        }
                                    }
                                }

                                .MenuGroup {
                                    &.menu-divider {
                                        background: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
