.root {
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.08);
    color: rgb(var(--time-webkit-color-secondary-rgb));
    cursor: default;
    outline: none;
    transition-duration: 150ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease-out;
}

.root:hover,
.root:active {
    background-color: rgb(var(--time-webkit-color-secondary-rgb), 0.12);
}

.root.large {
    padding: 4px 6px;
    border-radius: 6px;
}

.root.medium {
    padding: 4px 4px;
    border-radius: 5px;
}

.root.error {
    background-color: rgb(var(--time-webkit-color-error-rgb), 0.08);
}

.root.error:hover,
.root.error:active {
    background-color: rgb(var(--time-webkit-color-error-rgb), 0.12);
}

.root.warning {
    background-color: rgb(var(--time-webkit-color-warning-rgb), 0.08);
}

.root.warning:hover,
.root.warning:active {
    background-color: rgb(var(--time-webkit-color-warning-rgb), 0.12);
}

.root.success {
    background-color: rgb(var(--time-webkit-color-success-rgb), 0.08);
}

.root.success:hover,
.root.success:active {
    background-color: rgb(var(--time-webkit-color-success-rgb), 0.12);
}

.root.selected {
    box-shadow: 0 0 0 1px rgb(var(--time-webkit-color-sidebar-text-active-border-rgb)) inset;
}

.root.disabled {
    pointer-events: none;
}

.contentPadding {
    margin: 0 2px;
}

.content {
    overflow: hidden;
    font-size: 0;
    line-height: 0;
}

.label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.iconLeft,
.iconRight {
    font-size: 0;
    line-height: 0;
}

.root.large .iconLeft {
    padding-right: 4px;
}

.root.large .iconRight {
    padding-left: 4px;
}

.root.medium .iconRight {
    padding-left: 2px;
}

.root.medium .iconLeft {
    padding-right: 2px;
}

.buttonDelete {
    padding: 2px 0 0 0;
    border: 0;
    background: transparent;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.64);
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    transition-duration: 150ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease-out;
}

.buttonDelete:hover,
.buttonDelete:active,
.buttonDelete:focus {
    color: rgb(var(--time-webkit-color-secondary-rgb));
}

.root.large .buttonDelete {
    padding-left: 4px;
}

.root.medium .buttonDelete {
    padding-left: 2px;
}
