@import "sass/utils/functions";
@import "utils/variables";

.markdown-image-expand {
    position: relative;
    display: flex;
    align-items: center;
}

.markdown-image-expand__collapse-button {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 1px solid rgba(var(--center-channel-color-rgb), 0.16);
    background-color: var(--center-channel-bg);
    border-radius: 16px;
    box-shadow: $elevation-1;
    color: rgba(var(--center-channel-color-rgb), 0.56);
    font-size: 18px;
    line-height: 21px;

    .icon {
        width: 18px;
        height: 18px;
    }

    .markdown-image-expand--expanded:hover & {
        display: block;
    }

    &:hover {
        border: 1px solid rgba(var(--center-channel-color-rgb), 0.24);
        box-shadow: $elevation-2;
    }
}

/**
*   We have to set display: inline-block for both expand button and alt text.
*   The purpose of this is to get rid of weird looking text-decoration: underline caused
*   by potential nesting of this element within Markdown link
*/
.markdown-image-expand__expand-button {
    display: inline-block;
    padding: 0;
    border: none;
    background: transparent;
    border-radius: 4px;
    color: rgba(var(--center-channel-color-rgb), 0.56);
    font-size: 18px;
    line-height: 18px;

    &:hover {
        color: rgba(var(--center-channel-color-rgb), 0.72);
    }

    .icon {
        width: 18px;
        height: 18px;

        &::before {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.markdown-image-expand__expand-icon {
    display: inline-block;
    vertical-align: middle;
}

.markdown-image-expand__alt-text {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
}
