body {
    --time-webkit-font-family: "Lato", sans-serif;
    --time-webkit-color-black-rgb: 0, 0, 0;
    --time-webkit-color-whte-rgb: 255, 255, 255;

    /* buttonBg: "rgb(28, 88, 217)" */
    --time-webkit-color-main-rgb: var(--button-bg-rgb);

    /* centerChannelColor: "rgb(63, 67, 80)" */
    --time-webkit-color-secondary-rgb: var(--center-channel-color-rgb);

    /* errorTextColor: "rgb(210, 75, 78)" */
    --time-webkit-color-error-rgb: var(--error-text-color-rgb);
    --time-webkit-color-warning-rgb: 255, 188, 31;
    --time-webkit-color-success-rgb: 61, 184, 135;

    /* buttonColor: "rgb(255, 255, 255)" */
    --time-webkit-color-button-text-rgb: var(--button-color-rgb);

    /* buttonColor: "rgb(255, 255, 255)" */
    --time-webkit-color-button-destructive-text-rgb: var(--button-color-rgb);

    /* centerChannelBg: "rgb(255, 255, 255)" */
    --time-webkit-color-background-rgb: var(--center-channel-bg-rgb);

    /* sidebarBg: "rgb(30, 50, 92)", */
    --time-webkit-color-sidebar-bg-rgb: var(--sidebar-bg-rgb);

    /* sidebarText: "rgb(255, 255, 255)" */
    --time-webkit-color-sidebar-text-rgb: var(--sidebar-text-rgb);

    /* sidebarUnreadText: "rgb(255, 255, 255)" */
    --time-webkit-color-sidebar-unread-text-rgb: var(--sidebar-unread-text-rgb);

    /* sidebarTextHoverBg: "rgb(40, 66, 123)" */
    --time-webkit-color-sidebar-text-hover-bg-rgb: var(--sidebar-text-hover-bg-rgb);

    /* sidebarTextActiveBorder: "rgb(93, 137, 234)" */
    --time-webkit-color-sidebar-text-active-border-rgb: var(--sidebar-text-active-border-rgb);

    /* sidebarTextActiveColor: "rgb(255, 255, 255)" */
    --time-webkit-color-sidebar-text-active-color-rgb: var(--sidebar-text-active-color-rgb);

    /* sidebarHeaderBg: "rgb(25, 42, 77)" */
    --time-webkit-color-sidebar-header-bg-rgb: var(--sidebar-header-bg);

    /* sidebarHeaderTextColor: "rgb(255, 255, 255)" */
    --time-webkit-color-sidebar-header-text-color-rgb: var(--sidebar-header-text-color-rgb);

    /* sidebarTeamBarBg: "rgb(20, 33, 62)" */
    --time-webkit-color-sidebar-team-bar-bg-rgb: var(--sidebar-teambar-bg-rgb);

    /* onlineIndicator: "rgb(61, 184, 135)" */
    --time-webkit-color-online-indicator-rgb: var(--online-indicator-rgb);

    /* awayIndicator: "rgb(255, 188, 31)" */
    --time-webkit-color-away-indicator-rgb: var(--away-indicator-rgb);

    /* dndIndicator: "rgb(210, 75, 78)" */
    --time-webkit-color-dnd-indicator-rgb: var(--dnd-indicator-rgb);

    /* mentionBg: "rgb(255, 255, 255)" */
    --time-webkit-color-mention-bg-rgb: var(--mention-bg-rgb);

    /* mentionBj: "rgb(255, 255, 255)" */
    --time-webkit-color-mention-bf-rgb: var(--mention-bg-rgb);

    /* mentionColor: "rgb(30, 50, 92)" */
    --time-webkit-color-mention-color-rgb: var(--mention-color-rgb);

    /* newMessageSeparator: "rgb(204, 143, 0)" */
    --time-webkit-color-new-message-separator-rgb: var(--new-message-separator-rgb);

    /* linkColor: "rgb(56, 111, 229)" */
    --time-webkit-color-link-color-rgb: var(--link-color-rgb);

    /* mentionHighlightBg: "rgb(246, 229, 176)" */
    --time-webkit-color-mention-highlight-bg-rgb: var(--mention-highlight-bg-rgb);

    /* mentionHighlightLink: "rgb(18, 99, 163)" */
    --time-webkit-color-mention-highlight-link-rgb: var(--mention-highlight-link-rgb);
    --time-webkit-elevation-1: var(--elevation-1);
    --time-webkit-elevation-2: var(--elevation-2);
    --time-webkit-elevation-3: var(--elevation-3);
    --time-webkit-elevation-4: var(--elevation-4);

    /* codeTheme: "github" */
    --time-webkit-code-theme: "github";
}
