@charset 'UTF-8';

.no-results__holder {
    display: grid;
    width: 100%;
    height: 100%;
    place-content: center;
}

.no-results__wrapper {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin: auto;
    text-align: center;
    word-break: break-word;

    &.horizontal-layout {
        flex-direction: row;

        svg {
            flex: 0 0 auto;
            margin-right: 16px;
        }

        .no-results__text-container {
            display: flex;
            flex-direction: column;
            text-align: start;
        }

        .no-results__title {
            margin-bottom: 10px;
        }
    }
}

.no-results__emoji-wrapper {
    font-size: 80px;
    line-height: 80px;
}

.no-results__emoji-wrapper,
.no-results__variant-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 22px;
}

.no-results__variant-wrapper {
    width: 120px;
    height: 120px;
    background: rgba(var(--center-channel-color-rgb), 0.08);
    border-radius: 100%;
}

.no-results__title {
    margin: 0;
    margin-bottom: 15px;
    color: var(--center-channel-color);
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.no-results__icon {
    display: flex;

    svg {
        width: 62px;
        height: 62px;
        fill: var(--button-bg);
    }

    &.icon {
        color: var(--button-bg);
        font-size: 62px;
    }
}

.no-results__mini_icon {
    width: 11px;
    margin: 2px;
    vertical-align: middle;

    svg {
        fill: var(--button-bg);
    }
}
