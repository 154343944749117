.FullScreenModal {
    position: fixed;
    z-index: 1030 !important;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: var(--center-channel-bg);

    .close-x,
    .back {
        position: fixed;
        z-index: 9;
        top: 48px;
        width: 40px;
        height: 40px;
        padding: 0;
        border: 0;
        background: none;
        cursor: pointer;

        svg {
            width: 40px;
            height: 40px;
            padding: 4px;
            border-radius: 4px;
            fill: rgba(var(--center-channel-color-rgb), 0.56);

            &:hover {
                background: rgba(var(--center-channel-color-rgb), 0.08);
                fill: rgba(var(--center-channel-color-rgb), 0.72);
            }

            &:active {
                background: rgba(var(--button-bg-rgb), 0.08);
                fill: var(--button-bg);
            }
        }
    }

    .close-x {
        right: 48px;
    }

    .back {
        left: 48px;
    }

    // Animation
    &-enter,
    &-appear {
        opacity: 0;
    }

    &-enter-active,
    &-appear-active {
        opacity: 1;
        transition: opacity 100ms;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 100ms;
    }
}

.FullScreenModal.FullScreenModal--compact {
    .back {
        top: 8px;
        left: 16px;
    }

    .close-x {
        top: 8px;
        right: 16px;
    }

    .close-x,
    .back {
        position: absolute;
    }
}
