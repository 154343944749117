.input {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;

    /* remove a11y focus ring */
    box-shadow: none !important;
    color: rgb(var(--time-webkit-color-secondary-rgb));
    outline: none;
}

.root::placeholder {
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
}

.root {
    display: flex;
    width: 100%;
    align-items: flex-end;
    background: transparent;
    gap: 2px;
}
