.iconInfo {
    margin-left: 12px;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.56);
    cursor: pointer;
}

.domainListTitle {
    margin: 0;
    color: rgb(var(--time-webkit-color-whte-rgb));
}

.domainListSubtitle {
    margin: 0;
    color: rgb(var(--time-webkit-color-whte-rgb));
    text-align: center;
}

.domainList {
    padding: 0;
    margin: 0;
    color: rgb(var(--time-webkit-color-whte-rgb), 0.72);
    list-style-type: none;
}
