@charset "utf-8";

.status-icon {
    --padding: 1px;

    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border: var(--padding) solid var(--background);
    background: var(--background);
    border-radius: 100px;
    line-height: 0;

    &.status-dnd {
        background: var(--dnd-indicator);
    }

    &.status-online {
        background: var(--online-indicator);
    }

    &.status-away {
        background: var(--away-indicator);
    }

    &.status-offline {
        box-shadow: inset 0 0 0 1px rgba(var(--color-rgb), 0.5);
    }
}

.status-wrapper {
    position: relative;
    display: inline-block;
    align-self: center;
    line-height: 0;

    .sidebar--left & {
        width: 36px;
        height: 36px;
    }

    .status-icon {
        position: absolute;
        top: auto;
        right: calc(var(--padding) * -1);
        bottom: calc(var(--padding) * -1);
        width: 8px;
        height: 8px;
        border-width: var(--padding);
        margin: 0;
    }

    .status-icon-16,
    .Avatar-xxs + .status-icon,
    .profile-icon--xxs + .status-icon {
        right: -2px;
        bottom: -2px;
        width: 8px;
        height: 8px;
    }

    .status-icon-20,
    .Avatar-xs + .status-icon,
    .profile-icon--xs + .status-icon {
        right: -2px;
        bottom: -2px;
        width: 10px;
        height: 10px;
    }

    .status-icon-24,
    .Avatar-sm + .status-icon,
    .profile-icon--sm + .status-icon {
        right: -2px;
        bottom: -2px;
        width: 10px;
        height: 10px;
    }

    .status-icon-36,
    .Avatar-md + .status-icon,
    .profile-icon--md + .status-icon {
        --padding: 2px;

        right: -2px;
        bottom: -2px;
        width: 14px;
        height: 14px;
    }

    .status-icon-48,
    .Avatar-lg + .status-icon,
    .profile-icon--lg + .status-icon {
        --padding: 2px;

        width: 16px;
        height: 16px;
    }

    .status-icon-72,
    .Avatar-xl + .status-icon,
    .profile-icon--xl + .status-icon {
        --padding: 3px;

        width: 26px;
        height: 26px;
    }

    .status-icon-128,
    .Avatar-xxl + .status-icon,
    .profile-icon--xxl + .status-icon {
        --padding: 4px;

        width: 32px;
        height: 32px;
    }
}

.status {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 15px;
    margin: 0 7px 0 1px;

    &.status--group {
        top: 0;
        width: 15px;
        height: 15px;
        margin: 0 9px 0 -1px;
        border-radius: 2px;
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        text-align: center;
    }

    svg {
        max-height: 14px;
        backface-visibility: hidden;
    }

    .fa-lock {
        margin-left: 1px;
    }

    .fa-globe {
        position: relative;
        top: -1px;
    }
}
