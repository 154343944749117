/* System-like post's text should be different from user's posts */
:global(.post--system) .text {
    color: rgba(var(--time-webkit-color-secondary-rgb), 0.6);
}

.markdown__heading {
    width: 100%;
    margin: 8px 0;
    font-weight: 700;
    line-height: 1.5;
}

.markdown__heading:first-child {
    margin: 0 0 8px 0;
}

.markdown__heading:last-child {
    margin: 8px 0 0 0;
}

.markdown__heading:only-child {
    margin: 0;
}

h1.markdown__heading {
    font-size: 28px;
}

h2.markdown__heading {
    font-size: 25px;
}

h3.markdown__heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

h4.markdown__heading {
    font-size: 19px;
}

h5.markdown__heading {
    font-size: 15px;
}

h6.markdown__heading {
    font-size: 1em;
}

.markdown__heading :global(.emoticon) {
    min-width: 1.5em;
    min-height: 1.5em;
    vertical-align: top;
}
