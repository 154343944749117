.inputEmailsWrapper {
    margin-bottom: 20px;
}

.inputEmailsAlert {
    margin-top: 12px;
}

.error {
    margin-top: 8px;
}

.errorWithAction {
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 12px;
}

.errorWithAction > .error {
    margin: 0;
}

.inputChannels {
    display: block;
    margin-top: 12px;
}

.legend {
    border: 0;
    margin: 0 0 12px 0;
}

.noMatchesText {
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
}

.inputDescription {
    margin-top: 8px;
    color: rgb(var(--time-webkit-color-secondary-rgb), 0.72);
}

.footer {
    display: flex;
    padding: 20px 0;
    gap: 8px;
}

.copyInviteLinkWrapper {
    display: flex;
    flex: 1;
    align-items: center;
}

.spinner {
    margin-right: 8px;
}
