.root {
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
}

.text {
    overflow: hidden;
    visibility: hidden;
    white-space: pre;
}
